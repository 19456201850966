
import { computed, defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    }
  },
  setup: (props) => {
    const router = useRouter()
    const startX = ref(0)
    const startY = ref(0)
    const deltX = ref(0)
    const deltY = ref(0)
    const bottomBase = ref('200px')
    const rightBase = ref('30px')
    const bottom = computed(() => `calc(${bottomBase.value} - ${deltY.value}px)`)
    const right = computed(() => `calc(${rightBase.value} - ${deltX.value}px)`)
    return {
      bottom,
      right,
      gotoPage: () => {
        router.push(props.to)
      },
      touchStart: (e: any) => {
        const { pageX, pageY } = e.changedTouches[0]
        startX.value = pageX
        startY.value = pageY
      },
      touchMove: (e: any) => {
        const { pageX, pageY } = e.changedTouches[0]
        deltX.value = pageX - startX.value
        deltY.value = pageY - startY.value
      },
      touchEnd: () => {
        bottomBase.value = `${bottomBase.value} - ${deltY.value}px`
        rightBase.value = `${rightBase.value} - ${deltX.value}px`
        deltX.value = 0
        deltY.value = 0
      }
    }
  }
})
